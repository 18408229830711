import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player/youtube";
import { data } from "./data";
import BackButton from "../BackButton";
import { createQuickScanS3Folder } from "../../Store/quickScan";
import { tpscanName } from "../../Utils/AppDetails/appDetails";
import {
  buttonStyle,
  fontStyle,
  fontWeight,
  company_style_details,
  companyBucket,
} from "../../Utils/tpscanStyle";
import { postLogDetails } from "../../Services/APIs/appDetails";
const OnBoarding = () => {
  const { dentalOfficeName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStage, setStage] = useState(0);
  const { quickScanS3Folder } = useSelector((state) => state.quickScan);
  const handleSwipe = (x) => {
    if (x < 0 && currentStage > 0) {
      setStage((prevState) => prevState - 1);
    } else if (x > 0 && currentStage < data.length - 1) {
      setStage((prevState) => prevState + 1);
    }
  };

  // postLogDetails({
  //   uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
  //   app: JSON.parse(localStorage.getItem("companyBucket")).companyBucket,
  //   payLoad: `Viewed onboarding template ${currentStage + 1} `
  // })

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(1),
    onSwipedRight: () => handleSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleBack = () => {
    if (currentStage > 0) {
      setStage((prevState) => prevState - 1);
    } else {
      localStorage.getItem("usertpi")
        ? window.history.back()
        : navigate(`/${dentalOfficeName}/dashboard`, { replace: true });
    }
  };

  const openScan = () => {
    if (localStorage.getItem("actualCompany")) {
      navigate(`/banning-dental/full-scan`, { replace: true });
    }else if(localStorage.getItem("usertpi")){
      navigate(`/${tpscanName}/full-scan`, { replace: true });
    } else {
      !quickScanS3Folder && dispatch(createQuickScanS3Folder());
      navigate(`/${dentalOfficeName}/quick-scan`, { replace: true });
    }
  };
  if (JSON.parse(localStorage.getItem("reload")) == true && localStorage.getItem("usertpi")) {
    localStorage.setItem("reload", false)
    window.location.reload()
  }

  useEffect(() => {
    postLogDetails({
      uid: JSON.parse(localStorage.getItem("usertpi"))?.usertpi,
      app: JSON.parse(localStorage.getItem("companyBucket"))?.companyBucket,
      payLoad: `Visited onboarding page`
    })
  }, [])

  return (
    <div {...handlers} className=" h-full">
      <div className="h-3/4 relative bg-orange-100">
        {data[currentStage].video ? (
          <div className="pt-14 w-full h-4/5">
            <div className="w-full h-full relative overflow-x-hidden">
              <ReactPlayer
                url="https://youtu.be/pwMO4YGgdqg?si=tplxghBLev72uYoX"
                playing={false}
                className="absolute top-0 left-0"
                width="200%"
                height="100%"
                style={{ transform: "translate(-25%)" }}
              />
            </div>
          </div>
        ) : (
          <>
            <img
              src={data[currentStage].image}
              alt="slides"
              className="w-full h-full object-cover"
            />
            {data[currentStage].inner && (
              <img
                src={data[currentStage].inner}
                alt="inner"
                className="absolute top-24 h-80 w-80 object-contain"
              />
            )}
          </>
        )}
        <div className="absolute bottom-0 h-2/5 w-full bg-gradient-to-t from-white"></div>
      </div>
      <div
        className={
          companyBucket == "done" && currentStage == 0
            ? " flex justify-end mt-3 px-1 absolute top-0 w-full"
            : "flex justify-between px-1 absolute top-0 w-full"
        }
      >
        {companyBucket == "done" && currentStage == 0 ? (
          <></>
        ) : (
          <BackButton onClick={handleBack} />
        )}
        <button
          className="me-4 text-sm font-semibold"
          onClick={() => {
            console.log(localStorage.getItem("actualCompany")=="banning-dental")
            if(localStorage.getItem("actualCompany")){
              console.log("Cameinside if")
              navigate(`/banning-dental/full-scan`, { replace: true })
            }else{
              localStorage.getItem("usertpi")
              ? navigate(`/${tpscanName}/full-scan`, { replace: true })
              : navigate(`/${dentalOfficeName}/dashboard`, { replace: true });
            }
          
          }
        }
        >
          <span
            style={
              companyBucket == "done"
                ? { ...fontStyle, fontSize: "14px", fontWeight: "400", }
                : { ...fontStyle, ...fontWeight }
            }
          >
            Skip
          </span>
        </button>
      </div>
      <div className="w-full absolute bottom-0 px-5  custom-outline-xyz-o bg-white">
        <h1
          className="text-26  px-2 "
          style={
            companyBucket == "done"
              ? { ...fontStyle, fontSize: "26px", fontWeight: "500" }
              : { ...fontStyle, ...fontWeight }
          }
        >
          {data[currentStage].title}
        </h1>
        <p
          className="text-base px-2 mt-3"
          style={
            companyBucket == "done"
              ? { ...fontStyle, fontSize: "16px" }
              : { ...fontStyle, ...fontWeight }
          }
        >
          {data[currentStage].description}
        </p>
        <div className={companyBucket == "done" ? "text-center" : ""}>
          <button
            type="button"
            style={
              companyBucket == "done"
                ? { ...buttonStyle, ...fontStyle, borderRadius: "30px" }
                : { ...buttonStyle, ...fontStyle }
            }
            className={
              companyBucket == "done"
                ? "bg-black text-white  mb-1 mt-5 font-semibold text-base shadow-2xl rounded py-2.5 px-10"
                : "w-full bg-black text-white  h-11 mb-1 mt-5 font-semibold text-base shadow-2xl rounded"
            }
            onClick={() => {
              currentStage === data.length - 1 ? openScan() : handleSwipe(1);
            }}
          >
            {currentStage === data.length - 1
              ? localStorage.getItem("usertpi")
                ? "Scan Now"
                : "Take a Trial"
              : "Next"}
          </button>
        </div>

        <div className="text-center">
          {data.map((point, i) => {
            return (
              <span
                key={i}
                className={` ${currentStage === i ?
                  companyBucket == "done" ? "text-sky-500" : "text-black" : "text-gray-400"
                  } text-3xl`}
              >
                &#8226;
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OnBoarding;
